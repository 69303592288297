import { useNetworkStore } from "../network/networkStore";
import { connectMetamask, requestNetworkSwitch } from "./walletService";
import { useWalletStore } from "./walletStore";

export const useConnectWallet = () => {
  const setWalletAddress = useWalletStore((state) => state.setWalletAddress);
  const chainId = useNetworkStore((state) => state.chainId);
  const rpcUrl = useNetworkStore((state) => state.rpcUrl);
  const chainName = useNetworkStore((state) => state.chainName);
  const explorerUrl = useNetworkStore((state) => state.explorerUrl);
  const currency = useNetworkStore((state) => state.currency);

  const connect = async () => {
    // const metamaskConnection = await connectMetamask();
    // if (!metamaskConnection || "error" in metamaskConnection) {
    //   setWalletError("Error connecting to Metamask");
    //   console.error("Error connecting to Metamask");
    //   return;
    // }

    // const { address } = metamaskConnection;
    // setWalletAddress(address);
    requestNetworkSwitch({
      chainId,
      rpcUrl,
      chainName,
      explorerUrl,
      currency,
    });
  };

  const disconnect = async () => {
    setWalletAddress(null);
  };

  return { connect, disconnect };
};
