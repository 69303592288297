import React from 'react';
import BottomLogoImg from '../assets/images/bottom-logo.svg';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <>
      <div className='font-medium md:text-[60px] md:leading-[60px] text-[32px] leading-[32px] tracking-[-2px] text-center md:mt-[120px] mt-[80px] text-white font-borna w-fit max-w-[960px] mx-auto'>Explore our solutions today and transform your AI capabilities.</div>
      <div className='flex justify-center md:mt-[120px] mt-[90px]'>
        <img src={BottomLogoImg} alt='BottomLogoImg' />
      </div>
      <div className='flex md:flex-row flex-col justify-center items-center md:gap-[40px] gap-[24px] md:text-[16px] md:leading-[20px] text-[14px] leading-[16px] font-medium w-full md:mt-[60px] mt-[80px] pb-[70px] text-white'>
        <div className='w-full sm:w-auto text-center'>
          <a href='https://chestnut-radar-416.notion.site/VanaTensor-Docs-13b4cbed730c8066abdff693f13c92be?pvs=73'>Documentation</a>
        </div>
        <div className='w-full sm:w-auto text-center'>
          <Link to='/privacy-policy-terms-service'>Privacy Policy and Terms of Service</Link>
        </div>
        <div className='w-full sm:w-auto text-center'>
          <a href='https://discord.gg/GEpXgGKGBj'>Discord</a>
        </div>
        <div className='w-full sm:w-auto text-center'>
          <a href='mailto:Contact@vanatensor.com'>Contact Us</a>
        </div>
        <div className='w-full sm:w-auto text-center'>
          <a href='https://socialtensor.featurebase.app'>Feedback</a>
        </div>
      </div>
    </>
  )
}

export default Footer;
