import React, { useRef, useState } from 'react';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { CapsuleModal } from "@usecapsule/react-sdk";
import { CapsuleEvmProvider, metaMaskWallet } from "@usecapsule/evm-wallet-connectors";
import "@usecapsule/react-sdk/styles.css";
import { mainnet } from "wagmi/chains";
import VanaLogo from '../../assets/images/vana-logo.png';
import { useConnectWallet } from '../../hooks/wallet/walletHooks';
import { useWalletStore } from '../../hooks/wallet/walletStore';

interface UseCapsuleModalProps {
  isCapsuleModalOpen: boolean;
  setIsCapsuleModalOpen: (value: boolean) => void;
  capsuleClient: any;
}

const queryClient = new QueryClient();

const UseCapsuleModal: React.FC<UseCapsuleModalProps> = ({ isCapsuleModalOpen, setIsCapsuleModalOpen, capsuleClient }) => {
  const [shakeCaptchaModal, setShakeCaptchaModal] = useState(false);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const setWalletAddress = useWalletStore((state) => state.setWalletAddress);
  const { connect } = useConnectWallet();
  
  // Handle click outside the modal
  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setShakeCaptchaModal(true);
      setTimeout(() => setShakeCaptchaModal(false), 500);
    }
  };

  const handleClose = async () => {
    if (await capsuleClient.isFullyLoggedIn()) {
      const walletAddress = capsuleClient.currentExternalWalletAddresses;
      if (walletAddress) {
        setWalletAddress(walletAddress[0]);
        connect();
      }
    }
    setIsCapsuleModalOpen(false);
  };

  return (
    <div
      id="backdrop"
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center backdrop-blur z-[50]"
      onClick={handleOutsideClick}
    >
      <div
        ref={modalRef}
        className={`w-full xs:w-[600px] transform transition-transform duration-300 ${shakeCaptchaModal ? "shake" : ""
          }`}
      >
        <QueryClientProvider client={queryClient}>
          <CapsuleEvmProvider
            config={{
              appName: "VanaTensor",
              chains: [mainnet],
              projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID || "",
              ssr: false,
              wallets: [metaMaskWallet],
            }}>
            <div style={{ textAlign: "center", marginTop: "50px" }}>
              <CapsuleModal
                capsule={capsuleClient}
                isOpen={isCapsuleModalOpen}
                onClose={() => handleClose()}
                logo={VanaLogo}
                theme={{}}
                oAuthMethods={[]}
                disableEmailLogin={true}
                disablePhoneLogin={true}
                authLayout={["EXTERNAL:FULL"]}
                externalWallets={["METAMASK"]}
                twoFactorAuthEnabled={false}
                recoverySecretStepEnabled={true}
                onRampTestMode
              />
            </div>
          </CapsuleEvmProvider>
        </QueryClientProvider>
      </div>
    </div>
  )
}

export default UseCapsuleModal;
