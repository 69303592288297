import { useEffect } from "react";
import Footer from "../components/Footer";
import ScrollTopButton from "../components/common/ScrollTopButton";

const About: React.FC = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className='relative pt-[150px]'>
      <ScrollTopButton />
      <div className='md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1440px] m-auto px-5 md:px-10 2xl:px-20 relative z-[5]'>
      <div className="pb-10">
          <p className='text-[40px] md:text-[60px] leading-[60px] font-semibold text-center text-white'>About</p>
        </div>
        <div className='flex flex-col gap-[30px] rounded-[30px] bg-white p-4 md:p-[60px] max-w-[960px] mx-auto z-[3] relative'>
          <div>
            <div className='w-full flex flex-col gap-[20px]'>
              <p className='font-normal text-[20px] leading-[24px] text-[#000000B2]'>
                We are pioneers in decentralized AI, redefining how data and intelligence are created, owned, and monetized. At the core of our mission is the VanaTensor DLP, a revolutionary platform that combines human feedback with synthetic data to build high-quality datasets for training advanced AI models. Powered by the VANA network, we prioritize transparency, collaboration, and value distribution, ensuring that contributors, not centralized platforms, reap the rewards of their efforts.
              </p>
              <p className='font-normal text-[20px] leading-[24px] text-[#000000B2]'>
                By leveraging cutting-edge blockchain technology and innovative training mechanisms, we empower the VANA community to participate in a decentralized ecosystem where data becomes a liquid asset and AI evolves through collective intelligence. Our vision is to create a future where AI serves humanity equitably, fostering innovation and opportunity for all.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div >
  )
}

export default About;
