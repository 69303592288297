
export const WALLET_SIGN_FIXED_MESSAGE = "Please sign to retrieve your encryption key";
export const DLP_UPLOAD_STEP_DESCRIPTIONS = [
  "Started uploading dataset. Getting signature now...",
  "Getting publicKey from DLP...",
  "Encrypting key with wallet public key...",
  "Uploading data to S3...",
  "Adding file to Data Registry...",
  "Getting TEE details...",
  "Requesting contribution proof from TEE for File...",
  "Getting JobID for File...",
  "Getting Job details...",
  "Running RunProof on TEE validator...",
  "Requesting a reward..."
];

export enum UPLOAD_DATASET_STEPS {
  NOT_STARTED = 0,
  GET_SIGNATURE = 1,
  GET_PUBLIC_KEY = 2,
  ENCRYPT_KEY = 3,
  UPLOAD_DATA = 4,
  ADD_FILE_TO_DATA_REGISTRY = 5,
  GET_TEE_DETAILS = 6,
  REQUEST_CONTRIBUTION_PROOF = 7,
  GET_JOB_ID = 8,
  GET_JOB_DETAILS = 9,
  RUN_PROOF = 10,
  CLAIM_REWARD = 11,
}

export const TOUR_STEPS = [
  {
    selector: '[data-tour="vana-logo"]',
    content: 'Welcome to the VanaTensor Prompts Page! Here, users of our DLP have the opportunity to actively contribute valuable insight data that drives the development and refinement of advanced AI models. By participating, you become an integral part of our innovative ecosystem, helping to shape the future of decentralized intelligence through meaningful data contributions.',
  },
  {
    selector: '[data-tour="prompt"]',
    content: 'We kindly encourage users to thoroughly review the prompt question and each prompt response before submitting your answer. Thoughtful answers significantly enhance the quality of the data we use to train and refine our AI models. Your contributions play a critical role in ensuring our models achieve the highest standards of performance and reliability.',
  },
  {
    selector: '[data-tour="answer1"]',
    content: 'This is the initial prompt response in the set, serving as an important comparison for the subsequent prompt. Users should review it carefully and keep its context and details in mind as they  progress through the set.',
  },
  {
    selector: '[data-tour="answer2"]',
    content: 'This is the second prompt response. After reviewing it, users can begin evaluating which response they believe is more accurate and aligns most closely with the original prompt question.',
  },
  {
    selector: '[data-tour="click-option"]',
    content: 'Users have the option to submit their responses through one of four carefully designed answers, each correlating to an accuracy score.',
  },
  {
    selector: '[data-tour="submit-point"]',
    content: 'After users have completed the prompts, they can seamlessly connect their wallet to claim and redeem their earned rewards. This process ensures a secure and efficient way to receive the incentives associated with their valuable contributions.',
  },
];