import { useEffect } from "react";
import Footer from "../components/Footer";
import ScrollTopButton from "../components/common/ScrollTopButton";

const Staking: React.FC = () => {
  
  useEffect(() => {
    console.log("Staking component mounted");
    window.scrollTo({
      top: 0,
      behavior: "smooth", 
    });
  }, []);

  return (
    <div className='relative pt-[150px]'>
      <ScrollTopButton />
      <div className='md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1440px] m-auto px-5 md:px-10 2xl:px-20 relative z-[5]'>
        <div className="pb-10">
          <p className='text-[40px] md:text-[60px] leading-[60px] font-semibold text-center text-white'>Why Stake?</p>
        </div>
        <div className='flex flex-col gap-[30px] rounded-[30px] bg-white p-4 md:p-[60px] max-w-[960px] mx-auto z-[3] relative'>
          <div>
            <div className='w-full flex flex-col gap-[5px]'>
              <p className='font-normal text-[20px] leading-[24px] text-[#000000B2]'>
              Staking plays a vital role in supporting the VanaTensor ecosystem and the native Vana token. The VANA model empowers users to promote and support the AI models they believe in, ensuring value flows directly to that model. By staking, participants help maintain the ecosystem’s balance and sustainability, aligning incentives across the community
              </p>
            </div>
          </div>
         
        </div>
        <br></br>
        <br></br>
        <br></br>
        <div className="pb-10">
          <p className='text-[40px] md:text-[60px] leading-[60px] font-semibold text-center text-white'>Why Stake with Us?</p>
        </div>
        <div className='flex flex-col gap-[30px] rounded-[30px] bg-white p-4 md:p-[60px] max-w-[960px] mx-auto z-[3] relative'>
          <div>
            <div className='w-full flex flex-col gap-[5px]'>
              <p className='font-normal text-[20px] leading-[24px] text-[#000000B2]'>
              Staking with us means directly supporting the Vana ecosystem as whole and our innovative VanaTensor DLP. Your stake drives the creation of high-quality datasets, enhancing AI models while ensuring value flows back to contributors and yourself. By staking, you earn rewards tied to cutting-edge AI advancements and help power a decentralized network dedicated to redefining data ownership and AI development.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default Staking;
