import { toast } from "react-toastify";
import { TeePoolImplementation } from "./contracts/teePool";

export const fileJobIds = async (
  teePoolContract: TeePoolImplementation,
  fileId: number
) => {
  try {
    const jobIds = await teePoolContract.fileJobIds(fileId as any);
    return jobIds.map(Number);
  } catch (error) {
    console.error("Error fetching file job IDs:", error);
    throw error;
  }
};

export const getTeeDetails = async (
  teePoolContract: TeePoolImplementation,
  jobId: number
) => {
  try {
    const job = await teePoolContract.jobs(jobId as any) as any;
    const teeInfo = await teePoolContract.tees(job.teeAddress);

    return { ...job, teeUrl: teeInfo.url, teePublicKey: teeInfo.publicKey };
  } catch (error) {
    console.error("Error fetching job details:", error);
    toast.error(`${error}`, {
      position: "top-right",
      autoClose: 2500,
      style: { marginTop: "50px" }
    });
    throw error;
  }
};