import React, { useEffect, useState } from "react";
import { IGetStats } from "../utils/types";
import { getNextStats } from "../utils/apiServices";

const Stats = () => {
  const [stats, setStats] = useState<IGetStats>({
    totalPrompts: 0,
    avgTimesUsed: 0,
  });

  useEffect(() => {
    const getStats = async () => {
      const res = await getNextStats();
      if (res) setStats(res);
    }
    getStats();
  }, [])

  return <div className="min-h-screen w-full flex justify-center pt-[66px] relative">
    <div className="md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] m-auto xl:mt-[66px] mt-[10px] xl:px-[260px] px-[16px] w-full pb-[120px]">
      <div className="flex justify-center mt-[80px]">
        <div className="flex flex-col p-[16px] border border-white/30 rounded-[12px] text-white/50 text-[16px] leading-[18px] w-full gap-[30px]">
          <div className="flex justify-between">
            <span>Total prompts</span>
            <span>Average times used</span>
          </div>
          <div className="w-full h-[1px] bg-white/30" />
          <div className="flex items-center justify-between">
            <span className="text-white"> {stats.totalPrompts} </span>
            <div className="flex items-center gap-[8px]">
              <span className="text-green-500"> {stats.avgTimesUsed.toFixed(2)} ms </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
};

export default Stats;
