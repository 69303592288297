import React from "react";
import './ScoreRule.css'
import RuleSuccessImg from '..//assets/images/rule-success.svg'
import RuleFailImg from '..//assets/images/rule-faile.svg'
import RuleWarnImg from '..//assets/images/rule-warn.svg'
import ScrollTopButton from "../components/common/ScrollTopButton";

const ScoreRule = () => {
  return <div className="min-h-screen w-full flex justify-center pt-[66px] relative">
    <ScrollTopButton />
    <div className="max-w-[1288px] m-auto xl:mt-[66px] mt-[10px] xl:px-[260px] px-[16px] w-full xl:pb-0 pb-[120px]">
      <div className="xl:block hidden p-[16px] border border-white/30 rounded-[12px]">
        <table className="rule-table border-spacing-[24px]">
          <thead className="font-16-18  pb-[16px]">
            <tr >
              <th className="w-[200px]">Test</th>
              <th className="w-[46px]">Score</th>
              <th className="w-[40px]">Status</th>
              <th className="">Details</th>
            </tr>
          </thead>
          <tbody>
            <tr className="w-full h-[16px] border-b border-b-white/30"></tr>
            <tr className="w-full h-[16px]"></tr>
            <tr>
              <td>
                <div className="absolute top-0 left-0">
                  Time Minimums
                </div>
              </td>
              <td className="!text-[#FF4F4F]">
                <div className="absolute top-0 left-[50%] translate-x-[-50%]">
                  0.00%
                </div>
              </td>
              <td>
                <div className="absolute top-0 left-[50%] translate-x-[-50%]">
                  <img src={RuleFailImg} alt="" />
                </div>
              </td>
              <td>This is a classic example of a statistical phenomenon known as "correlation does not imply causation." Just because the study finds a correlation between gun.</td>
            </tr>
            <tr>
              <td>
                <div className="absolute top-0 left-0">
                  Time Minimums
                </div>
              </td>
              <td className="!text-[#97FF9B]">
                <div className="absolute top-0 left-[50%] translate-x-[-50%]">
                  100%
                </div>
              </td>
              <td>
                <div className="absolute top-0 left-[50%] translate-x-[-50%]">
                  <img src={RuleSuccessImg} alt="" />
                </div>
              </td>
              <td>
                Passed 0 out of 11 character timing checks
              </td>
            </tr>
            <tr>
              <td>
                <div className="absolute top-0 left-0">
                  Time Minimums
                </div>
              </td>
              <td className="!text-[#FFCC56]">
                <div className="absolute top-0 left-[50%] translate-x-[-50%]">
                  70.9%
                </div>
              </td>
              <td>
                <div className="absolute top-0 left-[50%] translate-x-[-50%]">
                  <img src={RuleWarnImg} alt="" />
                </div>
              </td>
              <td>
                Model selectiong distribution
              </td>
            </tr>
            <tr>
              <td>
                <div className="absolute top-0 left-0">
                  Time Minimums
                </div>
              </td>
              <td className="!text-[#FF4F4F]">
                <div className="absolute top-0 left-[50%] translate-x-[-50%]">
                  0.00%
                </div>
              </td>
              <td>
                <div className="absolute top-0 left-[50%] translate-x-[-50%]">
                  <img src={RuleFailImg} alt="" />
                </div>
              </td>
              <td>This is a classic example of a statistical phenomenon known as "correlation does not imply causation." Just because the study finds a correlation between gun.</td>
            </tr>
            <tr>
              <td>
                <div className="absolute top-0 left-0">
                  Time Minimums
                </div>
              </td>
              <td className="!text-[#97FF9B]">
                <div className="absolute top-0 left-[50%] translate-x-[-50%]">
                  100%
                </div>
              </td>
              <td>
                <div className="absolute top-0 left-[50%] translate-x-[-50%]">
                  <img src={RuleSuccessImg} alt="" />
                </div>
              </td>
              <td>
                Passed 0 out of 11 character timing checks
              </td>
            </tr>
            <tr>
              <td>
                <div className="absolute top-0 left-0">
                  Time Minimums
                </div>
              </td>
              <td className="!text-[#FFCC56]">
                <div className="absolute top-0 left-[50%] translate-x-[-50%]">
                  70.9%
                </div>
              </td>
              <td>
                <div className="absolute top-0 left-[50%] translate-x-[-50%]">
                  <img src={RuleWarnImg} alt="" />
                </div>
              </td>
              <td>
                Model selectiong distribution
              </td>
            </tr>
            <tr>
              <td>
                <div className="absolute top-0 left-0">
                  Time Minimums
                </div>
              </td>
              <td className="!text-[#FF4F4F]">
                <div className="absolute top-0 left-[50%] translate-x-[-50%]">
                  0.00%
                </div>
              </td>
              <td>
                <div className="absolute top-0 left-[50%] translate-x-[-50%]">
                  <img src={RuleFailImg} alt="" />
                </div>
              </td>
              <td>This is a classic example of a statistical phenomenon known as "correlation does not imply causation." Just because the study finds a correlation between gun.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="xl:hidden flex flex-col gap-[16px] p-[16px] border border-white/30 rounded-[12px] text-white/50 text-[16px] leading-[18px]">
        <div className="flex justify-between">
          <span>Test/Details</span>
          <span>Score/Status</span>
        </div>
        <div className="w-full h-[1px] bg-white/30" />
        <div className="flex items-center justify-between">
          <span className="text-white">Time Minimums</span>
          <div className="flex items-center gap-[8px]">
            <span className="text-[#FF4F4F]">0.00%</span>
            <img src={RuleFailImg} alt="" />
          </div>
        </div>
        <div>
          This is a classic example of a statistical phenomenon known as "correlation does not imply causation." Just because the study finds a correlation between gun.
        </div>
        <div className="flex items-center justify-between">
          <span className="text-white">Time Minimums</span>
          <div className="flex items-center gap-[8px]">
            <span className="text-[#FF4F4F]">0.00%</span>
            <img src={RuleFailImg} alt="" />
          </div>
        </div>
        <div>
          Passed 0 out of 11 character timing checks
        </div>
        <div className="flex items-center justify-between">
          <span className="text-white">Time Minimums</span>
          <div className="flex items-center gap-[8px]">
            <span className="text-[#FF4F4F]">0.00%</span>
            <img src={RuleFailImg} alt="" />
          </div>
        </div>
        <div>
          Passed 0 out of 11 character timing checks
        </div>
        <div className="flex items-center justify-between">
          <span className="text-white">Time Minimums</span>
          <div className="flex items-center gap-[8px]">
            <span className="text-[#FF4F4F]">0.00%</span>
            <img src={RuleFailImg} alt="" />
          </div>
        </div>
        <div>
          Passed 0 out of 11 character timing checks
        </div>
        <div className="flex items-center justify-between">
          <span className="text-white">Time Minimums</span>
          <div className="flex items-center gap-[8px]">
            <span className="text-[#FF4F4F]">0.00%</span>
            <img src={RuleFailImg} alt="" />
          </div>
        </div>
        <div>
          Passed 0 out of 11 character timing checks
        </div>
      </div>
      <div className="xl:absolute right-[24px] top-[130px] xl:w-fit w-full p-[12px] bg-white/5 rounded-[12px] flex flex-col gap-[12px] xl:mt-0 mt-[16px]">
        <div className="font-borna font-medium text-[16px] leading-[20px] text-white">Quality Assessment Report</div>
        <div className="flex justify-between items-end">
          <div className="text-white/70 text-[16px] leading-[18px]">Overall Score</div>
          <div className="text-[#97FFFE] font-semibold text-[26px] leading-[32px]">45%</div>
        </div>
      </div>
    </div>
  </div>;
};

export default ScoreRule;
