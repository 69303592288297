import React from 'react';

interface PromptResponseViewProps {
  response: string;
}

const PromptResponseView: React.FC<PromptResponseViewProps> = ({ response }) => {

  return (
    <div className="space-y-4">
      {response.includes('**') || response.includes('* ') ? (
        response.split('**').map((text, index) => {
          if (index === 0) return null;

          if (index % 2 === 1) {
            return (
              <h3 key={index} className="font-bold text-[16px] text-yellow-300">
                {text}
              </h3>
            );
          }

          if (text.includes('*')) {
            return (
              <ul key={index} className="list-disc pl-4 space-y-2">
                {text.split('*').map((bullet, bulletIndex) => (
                  bullet.trim() && <li key={bulletIndex}>{bullet.trim()}</li>
                ))}
              </ul>
            );
          }

          return <p key={index}>{text.trim()}</p>;
        })
      ) : (
        <p>{response}</p>
      )}
    </div>
  );
};

export default PromptResponseView;
