import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Prompt from './pages/Prompt';
import Header from './components/Header';
import Home from './pages/Home';
import Staking from './pages/Staking';
import PrivacyPolicyTermsOfService from './pages/PrivacyPolicyTermsOfService';
import About from './pages/About';
import { Analytics } from '@vercel/analytics/react';
import ScoreRule from './pages/ScoreRule';
import Stats from './pages/Stats';


function App() {
  return (
    <div>
      <BrowserRouter>
        <Header />
        <main className='font-borna'>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/prompt' element={<Prompt />} />
            <Route path='/privacy-policy-terms-service' element={<PrivacyPolicyTermsOfService />} />
            <Route path='/about' element={<About />} />
            <Route path='/rule' element={<ScoreRule />} />
            <Route path="/staking" element={<Staking />} />
            <Route path="/stats" element={<Stats />} />
          </Routes>
        </main>
        <Analytics />
      </BrowserRouter>
    </div>
  );
}

export default App;
