import React from 'react';

interface PromptLabelProps {
  handleClickOption: (method: string) => void;
}

const OptionButton: React.FC<{ label: string; emoji: string; onClick: () => void }> = ({ label, emoji, onClick }) => (
  <button
    onClick={onClick}
    className='hover-effect-button flex flex-1 justify-center items-center bg-[#97FFFE] rounded-[100px] text-[16px] leading-[20px] font-medium py-[16px] gap-[4px]'
  >
    <p>{emoji}</p>
    <p>{label}</p>
  </button>
);

const PromptLabel: React.FC<PromptLabelProps> = ({ handleClickOption }) => {
  return (
    <div className='flex md:flex-row flex-col gap-[12px] w-full max-w-[800px] md:mt-[24px] mt-[20px]'>
      <OptionButton label="A is better" emoji="👈" onClick={() => handleClickOption("A")} />
      <OptionButton label="B is better" emoji="👉" onClick={() => handleClickOption("B")} />
      <OptionButton label="Tie" emoji="🤝" onClick={() => handleClickOption("Both")} />
      <OptionButton label="Both are bad" emoji="👎" onClick={() => handleClickOption("None")} />
    </div>
  );
}

export default PromptLabel;
