import React, { useEffect, useRef, useState } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import VanaTensorLogo from "../assets/images/vana-tensor-logo.svg";
import VanaTensorLogoWhite from "../assets/images/vana-tensor-logo-white.svg";
import HexagonImg from "../assets/images/hexagon-white.svg";
import HexagonWhiteImg from "../assets/images/hexagon-white.svg";
import ArrowDownImg from "../assets/images/arrow-triangle-down-white.svg";
import MOkshaTestnetImg from "../assets/images/moksha-testnet.svg";
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useNetworkStore } from '../hooks/network/networkStore';
import { useWalletStore } from '../hooks/wallet/walletStore';
import { useConnectWallet } from '../hooks/wallet/walletHooks';

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [selectedNetworkMenu, setSelectedNetworkMenu] = useState("Vana Moksha Testnet");
  const { setNetwork } = useNetworkStore();
  const walletAddress = useWalletStore((state) => state.walletAddress);
  const { connect } = useConnectWallet();
  
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (isMenuOpen) document.body.classList.remove('overflow-hidden');
    else document.body.classList.add('overflow-hidden');
  };

  const handleScroll = () => {
    if (window.scrollY > 1) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };
  
  const handleSelectMokshaNetwork = () => {
    setSelectedNetworkMenu("Vana Moksha Testnet");
    setNetwork("moksha");
  }
  
  const handleSelectMainnetNetwork = () => {
    setSelectedNetworkMenu("Vana Mainnet");
    setNetwork("mainnet");
  }
  
  useEffect(() => {
    handleSelectMokshaNetwork();
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };

  }, []);

  useEffect(() => {
    if (walletAddress) {
      connect();
    }
  }, [selectedNetworkMenu])

  return (
    <header className={clsx('fixed w-full z-[10]', isScrolled && 'backdrop-blur-[40px]', isMenuOpen && 'bg-[#120f18]')}>
      <div className="md:px-[20px] px-[16px] py-[20px] flex items-center justify-between gap-2 font-borna">
        <div className='flex justify-between items-center gap-4 xl:gap-12 text-white font-medium text-[16px] leading-[20px]'>
          <div>
            <a href='/'>
              <img src={VanaTensorLogoWhite} alt='VanaTensorLogo' />
            </a>
          </div>

          <div className='hidden lg:block'><Link to='/'>Home</Link></div>
          <div className='hidden lg:block'><Link to='/staking'>Staking</Link></div>
          <div className='hidden lg:block'><Link to='/about'>About</Link></div>
        </div>

        <div className='flex justify-between items-center gap-[12px]'>
          <div className='hidden lg:block'>
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <MenuButton className="flex justify-between items-center  gap-[10px] rounded-[100px] h-fit px-[16px] py-[8px] text-[16px] leading-[20px] font-medium text-white bg-white/10 cursor-default">
                  <img src={MOkshaTestnetImg} alt='MOkshaTestnet' />
                  <span>{selectedNetworkMenu}</span>
                  <img src={ArrowDownImg} alt='ArrowDown' />
                </MenuButton>
              </div>

              <MenuItems
                transition
                className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <div className="py-1">
                  <MenuItem>
                    <button
                      onClick={() => handleSelectMokshaNetwork()}
                      className="block px-4 py-2 text-sm text-black data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                    >
                      Vana Moksha Testnet
                    </button>
                  </MenuItem>
                  <MenuItem>
                    <button
                      onClick={() => handleSelectMainnetNetwork()}
                      className="block px-4 py-2 text-sm text-black data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                    >
                      Vana Mainnet
                    </button>
                  </MenuItem>
                </div>
              </MenuItems>
            </Menu>
          </div>
          <Link
            to='/prompt' className='bg-white py-[10px] px-[24px] rounded-[100px] w-[86px] text-black  justify-center items-center text-[16px] font-medium leading-[20px] hidden lg:flex'
          >
            Train
          </Link>

          {/* temporarily hidden */}
          {/* <div className='hidden lg:block'>
            <img src={HexagonWhiteImg} alt="HexagonImg" className='cursor-pointer' />
          </div> */}
          <button onClick={toggleMenu} className={`lg:hidden hamburger ${isMenuOpen && 'active'} md:!w-[42px] md:!h-[42px] lg:!w-[42px] lg:!h-[42px] md:p-[13px] lg:p-[13px]`}>
            <span></span>
            <span></span>
          </button>
          <div ref={menuRef} className={`header-nav-menu ${isMenuOpen && 'active'} z-[100] block lg:hidden`}>
            <nav>
              <ul className="text-white flex flex-col gap-6">
                <li>
                  <Link to='/' onClick={toggleMenu}>Home</Link>
                </li>
                <li>
                  <Link to='/staking' onClick={toggleMenu}>Staking</Link>
                </li>
                <li>
                  <Link to='/about' onClick={toggleMenu}>About</Link>
                </li>
                <li>
                  <div className='w-full h-[1px] bg-[rgba(255,255,255,0.1)]' />
                </li>
                <li>
                  <div>
                    <Menu as="div" className="relative inline-block text-left w-full">
                      <div>
                        <MenuButton className="flex justify-between items-center w-full text-white">

                          <div className='flex gap-[10px] items-center font-medium text-[16px] leading-[20px]'>
                            <img src={MOkshaTestnetImg} alt='MOkshaTestnet' />
                            Vana Moksha Testnet
                          </div>
                          {/* <img src={ArrowDownImg} alt='ArrowDown' /> */}
                        </MenuButton>
                      </div>
                    </Menu>
                  </div>
                </li>
                <li>
                  <Link to='/prompt' onClick={toggleMenu} className='block font-medium text-[16px] leading-[20px] text-black w-full py-[14px] bg-white text-center rounded-[100px]'>
                    Train
                  </Link>
                </li>
                {/* temporarily hidden */}
                {/* <li>
                  <div className='font-medium text-[16px] leading-[20px] text-white/50 text-center' >
                    Settings
                  </div>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header;
