import { BrowserProvider } from "ethers";
import * as openpgp from "openpgp";
import { saveAs } from 'file-saver';

declare global {
  interface Window {
    ethereum: any;
  }
}

export async function clientSideEncrypt(
  file: File,
  signature: string,
): Promise<File> {
  const fileBuffer = await file.arrayBuffer();
  const message = await openpgp.createMessage({
    binary: new Uint8Array(fileBuffer),
  });

  const encrypted = await openpgp.encrypt({
    message,
    passwords: [signature],
    format: "binary",
  });

  // Convert WebStream<Uint8Array> to BlobPart
  const response = new Response(encrypted as ReadableStream<Uint8Array>);
  const arrayBuffer = await response.arrayBuffer();
  const uint8Array = new Uint8Array(arrayBuffer);

  const encryptedBlob = new Blob([uint8Array], {
    type: "application/octet-stream",
  });
  const encryptedFile = new File(
    [encryptedBlob],
    "",
    {
      type: "application/octet-stream",
    },
  );
  return encryptedFile;
}

export async function clientSideDecrypt(encryptedFile: File, signature: string): Promise<ArrayBuffer> {
  const encryptedData = await encryptedFile.arrayBuffer();
  const message = await openpgp.readMessage({
    binaryMessage: new Uint8Array(encryptedData),
  });

  const decrypted = await openpgp.decrypt({
    message,
    passwords: [signature],
    format: "binary",
  });

  // Convert the decrypted data to an ArrayBuffer
  const response = new Response(decrypted.data as ReadableStream<Uint8Array>);
  const arrayBuffer = await response.arrayBuffer();

  return arrayBuffer;
}

const test = async (jsonData: any, signature: string) => {
  const encryptedFile = await clientSideEncrypt(new File([JSON.stringify(jsonData)], "account.json"), signature);
  const encryptedFileblob = new Blob([encryptedFile], { type: 'application/octet-stream' });
  saveAs(encryptedFileblob, 'encrypted.json');

  const encryptedFileBlob = new Blob(['./encrypted.json'], { type: 'application/octet-stream' });
  const file = new File([encryptedFileblob], '', { type: 'application/octet-stream'}); // Create a File object

  const decryptedFile = await clientSideDecrypt(file, signature);
  const blob = new Blob([decryptedFile], { type: 'application/octet-stream' });
  saveAs(blob, 'decrypted.json');
  return
}

export const signMessage = async (
  address: string,
  message: string
): Promise<string> => {
  if (!window.ethereum) {
    throw new Error("MetaMask not found");
  }

  const provider = new BrowserProvider(window.ethereum);
  const signer = await provider.getSigner(address);
  const signature = await signer.signMessage(message);
  return signature;
};


// Helper function to convert hex string to Uint8Array
export const hexStringToUint8Array = (hex: string): number[] => {
  const result: number[] = [];
  for (let i = 0; i < hex.length; i += 2) {
    result.push(parseInt(hex.substr(i, 2), 16));
  }
  return result;
};