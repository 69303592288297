import UpArrowIcon from "../../assets/images/up-arrow-icon.svg";

const ScrollTopButton = () => {
  const handleMoveToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", 
    });
  };

  return (
    <div className="fixed bottom-[50px] right-[50px] z-[50]">
      <button
        className="bg-white text-black px-4 py-2 rounded-[100px] w-[50px] h-[50px]"
        onClick={handleMoveToTop}
      >
        <img src={UpArrowIcon} alt="UpArrowIcon" />
      </button>
    </div>
  );
};

export default ScrollTopButton;
