import React from 'react';

interface PromptSubmitGroupProps {
  count: number;
  walletAddress?: string;
  handleDepositPoints: () => void;
  setIsCapsuleModalOpen: (isCapsuleModalOpen: boolean) => void;
}

const PromptSubmitGroup: React.FC<PromptSubmitGroupProps> = React.memo(({ count, walletAddress, handleDepositPoints, setIsCapsuleModalOpen }) => {

  const buttonClass = 'bg-white w-full text-black md:py-[6px] py-[14px] rounded-[100px] text-[16px] leading-[18px] font-medium hover:bg-white/80 transition-colors';

  return (
    <>
      <div className='flex flex-col gap-[4px]'>
        <span className='text-white/70 font-400-14-16'>Expected Vana Rewards</span>
        <div className='text-[26px] leading-[32px] font-semibold text-white'>{count/10000}</div>
      </div>
      {/* <div className='flex flex-col gap-[4px] mt-[13px]'>
        <span className='text-white/70 font-400-14-16'>Value(CAD)</span>
        <div className='text-[26px] leading-[32px] font-semibold text-white'>
          {(count * 0.0212).toFixed(2)}
        </div>
      </div> */}
      <div className="w-full flex justify-center mt-[6px]">
        {walletAddress ?
          <button
            onClick={handleDepositPoints}
            className={buttonClass}
          >
            Claim
          </button>
          :
          <button
            onClick={() => setIsCapsuleModalOpen(true)}
            className={buttonClass}
          >
            Connect
          </button>
        }
      </div>
    </>
  );
});

export default PromptSubmitGroup;
