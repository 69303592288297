import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Verify } from 'react-puzzle-captcha';

interface CaptchaModalProps {
  setIsCaptchaModalOpen: (value: boolean) => void;
}

const CaptchaModal: React.FC<CaptchaModalProps> = ({ setIsCaptchaModalOpen }) => {
  const [shakeCaptchaModal, setShakeCaptchaModal] = useState(false);
  const modalRef = useRef<HTMLDivElement | null>(null);
  // Handle click outside the modal
  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setShakeCaptchaModal(true);
      setTimeout(() => setShakeCaptchaModal(false), 500);
    }
  };

  // Close the modal
  const closeModal = () => {
    toast.success('Captcha Succeeded!', {
      position: "top-right",
      autoClose: 3000,
      style: { marginTop: "50px" }
    });
    localStorage.setItem("PromptCalledCount", JSON.stringify(0));
    setIsCaptchaModalOpen(false);
  };

  return (
    <div
      id="backdrop"
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center backdrop-blur"
      onClick={handleOutsideClick}
    >
      <div
        ref={modalRef}
        className={`bg-white p-0 xs:p-6 rounded-lg shadow-lg w-full xs:w-[400px] transform transition-transform duration-300 ${shakeCaptchaModal ? "shake" : ""
          }`}
      >
        <Verify
          width={320}
          height={160}
          visible={true}
          onSuccess={() => closeModal()}
          onFail={() => {
            toast.error('Captcha Failed!', {
              position: "top-right",
              autoClose: 3000,
              style: { marginTop: "50px" }
            })
          }}
          onRefresh={() => {
            toast.warning('Please retry!', {
              position: "top-right",
              autoClose: 3000,
              style: { marginTop: "50px" }
            })
          }}
        />
      </div>
    </div>
  )
}

export default CaptchaModal;