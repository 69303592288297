import React, { useEffect, useState } from 'react';

import OwnYourDataImg from '../assets/images/own-your-data.svg';
import OwnYourDataAvatarImg from '../assets/images/own-your-data-avatar.svg';
import OwnYourDatIconImg from '../assets/images/own-your-data-icon.svg';
import SuperTextDLPImg from '../assets/images/super-text-dlp.png';
import APIAcessImg from '../assets/images/api-access.png';
import BlockchainBackedSecurityImg from '../assets/images/blockchain-backend-security.png';
import HumanRatedQualityImg from '../assets/images/human-rated-quality.png';
import ArrowRightWhiteImg from '../assets/images/arrow-right-white.svg';
import ArrowRightBlackImg from '../assets/images/arrow-right.svg';
import MinusImg from '../assets/images/minus.png';
import HowWorkBgImg from '../assets/images/how-works-back.jpeg';
import HowItWorkImg from '../assets/images/how-it-works.svg';
import HowItWorkFirstImg from '../assets/images/how-it-works-first.png';
import LogoMobileBlackCircleImg from '../assets/images/logo-mobile-black-circle.svg';
import './Home.css'
import clsx from 'clsx';
import LinearGradientAi from '../components/common/LinearGradientAi';
import Footer from '../components/Footer';
import ScrollTopButton from '../components/common/ScrollTopButton';

const Home: React.FC = () => {
  const [selectedFreeQuestionFirst, setSelectedFreeQuestionFirst] = useState(false);
  const [selectedFreeQuestionSecond, setSelectedFreeQuestionSecond] = useState(false);
  const [selectedFreeQuestionThird, setSelectedFreeQuestionThird] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className='relative'>
      <ScrollTopButton />
      <div className='md:pb-[220px] pb-[40px] relative m-auto px-[24px]'>
        <div className='pointer-events-none absolute left-0 top-0 w-full h-[150vh]'>
          <div className='bg-gradient-to-t from-white/0 to-[#121018] absolute left-[0] top-[0] w-full h-[50%] z-[2]'></div>
          <video src='/videos/hand.webm' className='absolute z-[1] w-full h-[150vh] object-cover' autoPlay loop muted playsInline />
          <div className='bg-gradient-to-b from-white/0 to-[#121018] absolute left-[0] bottom-[0] w-full h-[50%] z-[2]'></div>
        </div>
        <div className='md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1440px] m-auto z-[2] relative'>
          <div className='h-[100vh] flex flex-col items-center justify-center relative'>
            <div className='flex flex-col justify-center items-center relative mt-[-50px] text-white'>
              <div className='font-medium md:text-[100px] md:leading-[100px] z-[1] text-center font-borna text-[46px] leading-[46px]'>Human insight.</div>
              <div className='font-medium md:text-[100px] md:leading-[100px] z-[3] text-center font-borna text-[46px] leading-[46px]'>AI precision.</div>
              <div className='z-[3] md:mt-[33px] md:mb-[40px] my-[24px] font-normal md:text-[20px] md:leading-[24px] font-16-18 md:text-start text-center'>Advanced data liquidity pool built on the Vana blockchain</div>

              <div className='flex justify-center relative z-[3]'>
                <a
                  href='/prompt'
                  className='md:text-[20px] md:leading-[24px] font-16-18 font-medium md:px-[40px] md:py-[20px] px-[24px] py-[17px] bg-black rounded-[100px] text-white'
                >
                  Train VanaTensor Now
                </a>
              </div>
            </div>
            <div className='absolute bottom-[13%]'>
              <div className="scroll-down">
                <div className="container_mouse">
                  <span className="mouse-btn">
                    <span className="mouse-scroll"></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='md:mt-[163px] mt-[140px] flex flex-col items-center md:gap-[40px] gap-[24px]'>
            <p className='md:text-[60px] md:leading-[60px] text-[32px] leading-[32px] font-medium text-center font-borna text-white'>Who We Are</p>
            <p className='md:text-[20px] md:leading-[24px] font-16-18 font-normal text-center text-[rgba(255,255,255,0.7)] max-w-[960px]'>We are VanaTensor, a leader in AI-powered solutions, dedicated to providing high-quality synthetic and human-verified data. By combining human feedback with cutting-edge technology, we enhance the training of large language models, offering better, smarter AI outputs.</p>
          </div>
        </div>
      </div>

      <div className='px-[24px] md:pb-[220px] pb-[80px] overflow-x-hidden'>
        <div className='flex md:flex-row flex-col-reverse md:gap-[40px] gap-[10px] items-center justify-between mx-auto max-w-[960px] relative z-[5]'>
          <div className='flex flex-col md:gap-[40px] gap-[24px]'>
            <p className='md:text-[60px] md:leading-[60px] text-[32px] leading-[32px] font-medium text-white font-borna tracking-[-2px] md:text-start text-center'>Own Your Data</p>
            <p className='md:text-[20px] md:leading-[24px] font-16-18 font-normal text-[rgba(255,255,255,0.7)] md:max-w-[415px] text-center md:text-start'>Your data, your control. Our platform is built on decentralized technology, ensuring you have full ownership and transparency of your data without third-party interference.</p>
          </div>
          <div className='flex justify-center items-center mt-6 md:mt-0 relative z-[5] md:scale-[1] scale-[0.6]'>
            <img src={OwnYourDataImg} alt='OwnYourDataImg' className='w-[324px] h-[208px]' />
            <img src={OwnYourDataAvatarImg} alt='OwnYourDataImg' className='absolute left-[45px] top-[26px] z-[2]' />
            <div className='w-[120px] h-[120px] rounded-[15px] backdrop-blur-[40px] bg-white/10 flex items-center justify-center absolute left-[65px] top-0'>
              <img src={OwnYourDatIconImg} alt='OwnYourDataImg' className='w-[65px] h-[65px]' />
            </div>
          </div>
        </div>


        <div className='m-auto  md:mt-[220px] mt-[80px] relative z-[5]'>
          <div className='md:text-[60px] md:leading-[60px] text-[32px] leading-[32px] font-medium text-center text-white w-fit mx-auto font-borna tracking-[-2px]'>Why Choose Us?</div>
          <div className='block md:flex justify-center items-center w-full md:mt-[80px] mt-[40px] max-w-[960px] mx-auto'>
            <div className='flex flex-col justify-center md:items-start items-center w-full md:w-1/2 md:p-[32px] p-[16px]'>
              <p className='font-medium text-white md:text-[32px] md:leading-[32px] text-[24px] leading-[24px] font-borna'>Human-Centered AI</p>
              <div className='md:mt-[43px] md:mb-[50px] mt-[20px] mb-[20px] md:pl-[90px] flex justify-center md:justify-start'>
                <LinearGradientAi />
              </div>
              <p className='md:text-[20px] md:leading-[24px] font-16-18 font-normal text-[rgba(255,255,255,0.7)] w-full md:text-start text-center'>We integrate real human feedback to enhance AI decision-making</p>
            </div>
            <div className='w-full md:w-1/2 flex flex-col justify-center items-center gap-4 mt-4 md:mt-0'>
              <div className='flex flex-col md:gap-[24px] gap-[8px] justify-center bg-[rgba(255,255,255,0.1)] md:rounded-[30px] rounded-[10px] md:p-[32px] p-[16px] w-full md:max-w-[468px] text-center md:text-start'>
                <p className='font-medium md:text-[32px] md:leading-[32px] text-[24px] leading-[24px] text-white font-borna'>Cutting-Edge Technology</p>
                <p className='font-normal md:text-[20px] md:leading-[24px] font-16-18 text-[rgba(255,255,255,0.7)]'>Powered by the latest in decentralized tech and advanced models</p>
              </div>
              <div className='flex flex-col md:gap-[24px] gap-[8px] justify-center bg-[#97FFFE] md:rounded-[30px] rounded-[10px] md:p-[32px] p-[16px] w-full md:max-w-[468px] text-center md:text-start'>
                <p className='font-medium md:text-[32px] md:leading-[32px] text-[24px] leading-[24px] text-black font-borna'>Continuous Improvement</p>
                <p className='font-normal md:text-[20px] md:leading-[24px] font-16-18 text-[rgba(0,0,0,0.7)]'>Our AI models get better every day, thanks to iterative feedback loops</p>
              </div>
            </div>
          </div>
          <div className='flex flex-col md:gap-[24px] gap-[8px] md:mt-[24px] mt-[16px] bg-[rgba(255,255,255,0.1)] md:rounded-[30px] rounded-[10px] mx-auto md:p-[32px] p-[16px] max-w-[960px] text-center md:text-start'>
            <p className='font-medium md:text-[32px] md:leading-[32px] text-[24px] leading-[24px] text-white font-borna'>Custom Solutions</p>
            <p className='font-normal md:text-[20px] md:leading-[24px] font-16-18 text-[rgba(255,255,255,0.7)]'>Tailor our API and tools to fit your specific needs—whether it's text generation or model refinement</p>
          </div>


          <div className='md:text-[60px] md:leading-[60px] text-[32px] leading-[32px] font-medium text-center text-white md:mt-[220px] mt-[80px] w-fit mx-auto tracking-[-2px] relative z-[5] font-borna'>Key Features</div>
          <div className='flex justify-between md:flex-row flex-col w-full md:mt-[80px] mt-[40px] md:gap-[38px] gap-[16px] relative max-w-[960px] mx-auto z-[5]'>
            <div className='flex md:flex-row flex-col items-start justify-center w-full lg:w-1/2  md:gap-[38px] gap-[16px]'>
              <div className='w-full md:w-1/2 flex flex-col items-center md:gap-[24px] gap-[16px]'>
                <div className='flex justify-center'>
                  <img src={SuperTextDLPImg} alt='SuperTextDLPImg' className='md:w-[80px] md:h-[80px] w-[60px] h-[60px]' />
                </div>
                <p className='font-semibold md:text-[22px] md:leading-[22px] text-[18px] leading-[18px] text-white text-center'>SuperText DLP</p>
                <p className='font-normal md:text-[20px] md:leading-[24px] font-16-18 text-[rgba(255,255,255,0.7)] text-center'>Tailor our API and tools to fit your specific needs—whether it's text generation or model refinement</p>
              </div>
              <div className='w-full md:w-1/2 flex flex-col items-center md:gap-[24px] gap-[16px]'>
                <div className='flex justify-center'>
                  <img src={APIAcessImg} alt='APIAcessImg' className='md:w-[80px] md:h-[80px] w-[60px] h-[60px]' />
                </div>
                <p className='font-semibold md:text-[22px] md:leading-[22px] text-[18px] leading-[18px] text-white text-center]'>API Access</p>
                <p className='font-normal md:text-[20px] md:leading-[24px] font-16-18 text-[rgba(255,255,255,0.7)] text-center'>Plug into our API to integrate refined, top-quality data into your project</p>
              </div>
            </div>
            <div className='flex md:flex-row flex-col items-start justify-center w-full lg:w-1/2 md:gap-[38px] gap-[16px]'>
              <div className='w-full md:w-1/2 flex flex-col items-center md:gap-[24px] gap-[16px]'>
                <div className='flex justify-center'>
                  <img src={BlockchainBackedSecurityImg} alt='BlockchainBackedSecurityImg' className='md:w-[80px] md:h-[80px] w-[60px] h-[60px]' />
                </div>
                <p className='font-semibold md:text-[22px] md:leading-[22px] text-[18px] leading-[18px] text-white text-center'>Blockchain-Backed Security</p>
                <p className='font-normal md:text-[20px] md:leading-[24px] font-16-18 text-[rgba(255,255,255,0.7)] text-center'>Decentralized and secure, ensuring your data stays yours</p>
              </div>
              <div className='w-full md:w-1/2 flex flex-col items-center md:gap-[24px] gap-[16px]'>
                <div className='flex justify-center'>
                  <img src={HumanRatedQualityImg} alt='HumanRatedQualityImg' className='md:w-[80px] md:h-[80px] w-[60px] h-[60px]' />
                </div>
                <p className='font-semibold md:text-[22px] md:leading-[22px] text-[18px] leading-[18px] text-white text-center'>Human-Rated Quality</p>
                <p className='font-normal md:text-[20px] md:leading-[24px] font-16-18 text-[rgba(255,255,255,0.7)] text-center'>Each piece of generated content is rated and ranked by real, human users to ensure optimal quality</p>
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-col justify-center relative md:pt-[427px] pt-[192px]'>

          <div className='flex flex-col md:gap-[40px] gap-[24px] items-center z-[2] relative'>
            <span className='md:text-[60px] md:leading-[60px] text-[32px] leading-[32px] font-medium text-white tracking-[-2px] font-borna relative z-[2]'>How It Works</span>
            <span className='font-normal md:text-[20px] md:leading-[24px] font-16-18 text-[rgba(255,255,255,0.7)] relative z-[2] text-center'>Our process blends human insights with synthetic data generation</span>
            <div className='w-full md:max-w-[1440px] md:min-w-[1440px] max-w-[850px] min-w-[850px] aspect-[1449/910] absolute top-[50%] md:translate-y-[-60%] translate-y-[-70%] left-[50%] translate-x-[-50%] z-[1]'>
              <div className='bg-gradient-to-t from-white/0 to-[#121018] absolute left-[0] top-[0] w-full h-[30%] z-[2]'></div>
              <div className='bg-gradient-to-b from-white/0 to-[#121018] absolute left-[0] bottom-[0] w-full h-[30%] z-[2]'></div>
              <div className='bg-gradient-to-r from-white/0 to-[#121018] absolute right-[0] top-[0] w-[30%] h-full z-[2]'></div>
              <div className='bg-gradient-to-l from-white/0 to-[#121018] absolute left-[0] top-[0] w-[30%] h-full z-[2]'></div>
              <img src={HowWorkBgImg} alt='HowWorkImg' className='' />
            </div>
          </div>
          <div className='w-[100%] max-w-[960px] mx-auto z-[2] md:mt-[112px] mt-[40px] relative lg:block flex flex-col gap-[24px] items-center'>
            <div className='font-semibold text-[42px] leading-[42px] text-white lg:hidden block'>1</div>
            <img src={HowItWorkFirstImg} className='lg:w-[442px] aspect-[442/250] lg:absolute top-[60px] left-[90px] z-[1]' alt='HowWorkImg' />
            <div className='font-16-18 text-white lg:hidden block text-center'>AI generates multiple responses using advanced models</div>
            <div className='font-semibold text-[42px] leading-[42px] text-white lg:hidden block'>2</div>
            <div className='flex flex-col gap-[12px] font-medium text-[16px] leading-[20px] text-white lg:absolute right-[33px] top-[330px]'>
              <div className='border-[2px] border-[#3A3A3A] rounded-[100px] w-[232px] h-[52px] flex items-center justify-center lg:text-[16px] lg:leading-[20px] font-16-18'>
                👈 A is better
              </div>
              <div className='border-[2px] border-[#3A3A3A] rounded-[100px] w-[232px] h-[52px] flex items-center justify-center lg:text-[16px] lg:leading-[20px] font-16-18'>
                👉 B is better
              </div>
              <div className='border-[2px] border-[#3A3A3A] rounded-[100px] w-[232px] h-[52px] flex items-center justify-center lg:text-[16px] lg:leading-[20px] font-16-18'>
                🤝 Tie
              </div>
              <div className='border-[2px] border-[#3A3A3A] rounded-[100px] w-[232px] h-[52px] flex items-center justify-center lg:text-[16px] lg:leading-[20px] font-16-18'>
                👎 Both are bad
              </div>
            </div>
            <div className='font-16-18 text-white lg:hidden block text-center'>Humans rank these responses, improving the AI over time</div>
            <div className='font-semibold text-[42px] leading-[42px] text-white lg:hidden block'>3</div>
            <div className='lg:hidden rounded-[20px] bg-[#97FFFE] p-[20px] pr-[40px] flex gap-[16px] items-center'>
              <img src={LogoMobileBlackCircleImg} alt="logo" />
              <div className='flex flex-col gap-[4px] '>
                <span className='text-[rgba(0,0,0,0.7)] font-16-18'>Prompt</span>
                <span className='text-black text-[16px] leading-[20px] font-medium'>Which memecoin is better?</span>
              </div>
            </div>
            <div className='font-16-18 text-white lg:hidden block text-center'>Verified data is used to train and refine models, creating a continuous loop of improvement</div>
            <img src={HowItWorkImg} className='w-[100%] lg:block hidden' alt='HowWorkImg' />
          </div>
        </div>
      </div>


      <div className='relative z-[5]'>
        <div className='md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1440px] m-auto px-5 md:px-10 2xl:px-20 relative z-[5]'>
          <div className='flex md:flex-row flex-col justify-between w-full max-w-[960px] mx-auto'>
            <div className='hidden flex-col gap-2 tracking-[-2px] text-white text-left w-fit text-[60px] leading-[60px] font-medium font-borna md:flex'>
              <span>Frequently</span>
              <span>Asked</span>
              <span>Questions</span>
            </div>
            <div className='flex flex-col items-center w-full tracking-[-2px] text-white text-left text-[32px] leading-[32px] font-medium font-borna md:hidden'>
              <span>Frequently Asked</span >
              <span>Questions</span>
            </div>
            <div className='w-full md:w-1/2 flex flex-col gap-[12px] mt-[24px] md:mt-0'>
              <div
                className='bg-[#97FFFE] md:rounded-[30px] rounded-[20px] p-6 flex flex-col cursor-pointer w-full relative transition-all duration-500 ease-in-out overflow-hidden group'
                onClick={() => setSelectedFreeQuestionFirst(!selectedFreeQuestionFirst)}
              >
                <div className='opacity-0 group-hover:opacity-100 w-full h-full absolute left-0 top-0 bg-black/10 transition-all duration-200 ease-in-out'></div>
                <div className='flex justify-between items-center'>
                  <p className='font-medium md:text-[20px] md:leading-[24px] text-[18px] leading-[22px] text-black text-left'>What makes your platform different?</p>
                  <div className='relative w-[16px] h-[16px]'>
                    <img src={MinusImg} className={clsx('w-[16px] h-[16px] absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]')} alt='view' />
                    <img src={MinusImg} className={clsx('w-[16px] h-[16px] ', selectedFreeQuestionFirst ? 'animate-rotate-in' : 'animate-rotate-out')} alt='view' />
                  </div>
                </div>
                <div className={clsx('font-normal md:text-[20px] md:leading-[24px] font-16-18 text-[rgba(0,0,0,0.7)] text-left max-h-0 transition-all duration-200 ease-in-out overflow-hidden', selectedFreeQuestionFirst && 'max-h-[200px] mt-[16px]')}>
                  We train our models with a blend of synthetic data and human input, ensuring accuracy and realism in our model ensemble.
                </div>
              </div>
              <div
                className='bg-[#97FFFE] md:rounded-[30px] rounded-[20px] p-6 flex flex-col cursor-pointer w-full relative transition-all duration-200 ease-in-out overflow-hidden group'
                onClick={() => setSelectedFreeQuestionSecond(!selectedFreeQuestionSecond)}
              >
                <div className='opacity-0 group-hover:opacity-100 w-full h-full absolute left-0 top-0 bg-black/10 transition-all duration-200 ease-in-out'></div>
                <div className='flex justify-between items-center'>
                  <p className='font-medium md:text-[20px] md:leading-[24px] text-[18px] leading-[22px] text-black text-left'>How can I use this?</p>
                  <div className='relative w-[16px] h-[16px]'>
                    <img src={MinusImg} className={clsx('w-[16px] h-[16px] absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]')} alt='view' />
                    <img src={MinusImg} className={clsx('w-[16px] h-[16px] ', selectedFreeQuestionSecond ? 'animate-rotate-in' : 'animate-rotate-out')} alt='view' />
                  </div>
                </div>
                <div>
                  <div className={clsx('font-normal md:text-[20px] md:leading-[24px] font-16-18 text-[rgba(0,0,0,0.7)] text-left max-h-0 transition-all duration-200 ease-in-out overflow-hidden', selectedFreeQuestionSecond && 'max-h-[200px] mt-[16px]')}>
                    Getting started is easy—integrate our API or use our web app to begin prompting our ensemble of models.
                  </div>
                </div>
              </div>
              <div
                className='bg-[#97FFFE] md:rounded-[30px] rounded-[20px] p-6 flex flex-col cursor-pointer w-full relative transition-all duration-200 ease-in-out overflow-hidden group'
                onClick={() => setSelectedFreeQuestionThird(!selectedFreeQuestionThird)}
              >
                <div className='opacity-0 group-hover:opacity-100 w-full h-full absolute left-0 top-0 bg-black/10 transition-all duration-200 ease-in-out'></div>
                <div className='flex justify-between items-center'>
                  <p className='font-medium md:text-[20px] md:leading-[24px] text-[18px] leading-[22px] text-black text-left'>Is my data secure?</p>
                  <div className='relative w-[16px] h-[16px]'>
                    <img src={MinusImg} className={clsx('w-[16px] h-[16px] absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]')} alt='view' />
                    <img src={MinusImg} className={clsx('w-[16px] h-[16px] ', selectedFreeQuestionThird ? 'animate-rotate-in' : 'animate-rotate-out')} alt='view' />
                  </div>
                </div>
                <div>
                  <div className={clsx('font-normal md:text-[20px] md:leading-[24px] font-16-18 text-[rgba(0,0,0,0.7)] text-left max-h-0 transition-all duration-200 ease-in-out overflow-hidden', selectedFreeQuestionThird && 'max-h-[200px] mt-[16px]')}>
                    Yes, your data is and will always remain secure. We use advanced decentralized technology and encryption methods to ensure your data remains private and protected.
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className='md:text-[60px] md:leading-[60px] text-[32px] leading-[32px] font-medium text-center text-white md:mt-[220px] mt-[80px] w-fit mx-auto tracking-[-2px] font-borna'>Get Started in 3 Steps</div>
          <div className='md:mt-[80px] mt-[24px] flex justify-between md:flex-row flex-col md:gap-[38px] gap-[24px] w-full max-w-[960px] mx-auto relative'>

            <div className='flex flex-col items-center gap-[24px] w-full lg:w-1/3'>
              <div className='flex justify-center items-center relative home-steps-index w-[80px] h-[80px]'>
                <p className='text-[56px] text-white font-bold leading-[67px] h-[67px]'>1</p>
              </div>
              <p className='text-white md:text-[22px] md:leading-[22px] text-[18px] leading-[18px] font-semibold text-center'>Sign Up</p>
              <p className='text-[rgba(255,255,255,0.7)] md:text-[20px] md:leading-[24px] text-[16px] font-normal text-center'>Create your account and get started</p>
            </div>

            <div className='flex justify-center items-center w-[32px] h-[32px] mx-auto my-auto'>
              <img src={ArrowRightWhiteImg} className='w-[32px] lg:rotate-0 rotate-90' alt='ArrowRightImg' />
            </div>

            <div className='flex flex-col items-center gap-[24px] w-full lg:w-1/3 '>
              <div className='flex justify-center items-center relative home-steps-index w-[80px] h-[80px]'>
                <div className='text-[56px] text-white font-bold leading-[67p h-[67px]x]'>2</div>
              </div>
              <p className='text-white md:text-[22px] md:leading-[22px] text-[18px] leading-[18px] font-semibold text-center'>Upload Your Data</p>
              <p className='text-[rgba(255,255,255,0.7)] md:text-[20px] md:leading-[24px] text-[16px] font-normal text-center'>Access and integrate your data into our DLP</p>
            </div>

            <div className='flex justify-center items-center w-[32px] h-[32px] mx-auto my-auto'>
              <img src={ArrowRightWhiteImg} className='w-[32px] lg:rotate-0 rotate-90' alt='ArrowRightImg' />
            </div>

            <div className='flex flex-col items-center gap-[24px] w-full lg:w-1/3'>
              <div className='flex justify-center items-center relative home-steps-index w-[80px] h-[80px]'>
                <p className='text-[56px] text-white font-bold leading-[67px] h-[67px]'>3</p>
              </div>
              <p className='text-white md:text-[22px] md:leading-[22px] text-[18px] leading-[18px] font-semibold text-center'>Monetize</p>
              <p className='text-[rgba(255,255,255,0.7)] md:text-[20px] md:leading-[24px] font-16-18 font-normal text-center'>Become a validator or contributor and earn by helping improve our models</p>
            </div>
          </div>

          <div className='md:mt-[220px] mt-[80px] md:pb-[120px] pb-[80px] z-[3] relative'>
            <p className='md:text-[60px] md:leading-[60px] text-[32px] leading-[32px] font-medium text-center w-fit mx-auto text-white font-borna tracking-[-2px] relative z-[2]'>Monetize with Us</p>
            <p className='md:text-[20px] md:leading-[24px] font-16-18 font-normal text-center md:mt-[40px] mt-[24px] max-w-[960px] mx-auto text-[rgba(255,255,255,0.7)] relative z-[2]'>Join our ecosystem to monetize your contributions. Data Contributors can submit their personal data and get rewarded. Validators can set up their own APIs or sell request capacity through NicheTensor, earning directly from the AI models they support.</p>
            <div className='flex justify-center md:mt-[40px] mt-[24px] relative z-[2]'>
              <a
                href="https://discord.gg/GEpXgGKGBj"
                target="_blank"
                rel="noopener noreferrer"
                className='flex justify-center items-center bg-[#97FFFE] md:px-[40px] md:py-[20px] px-[24px] py-[17px] text-black md:text-[20px] md:leading-[24px] font-16-18 font-medium rounded-[100px]'
              >
                Join Discord Now
              </a>
            </div>
            <div className='md:block hidden absolute left-[-300px] top-[-300px] bg-[rgb(125, 69, 150)] w-[500px] h-[500px]' style={{ boxShadow: '500px 500px rgb(125, 69, 150)', filter: 'blur(300px)' }} />
            <div className='md:block hidden absolute right-[500px] top-[-400px] bg-[rgb(151, 255, 254)] w-[500px] h-[500px]' style={{ boxShadow: '500px 500px rgb(151, 255, 254)', filter: 'blur(300px)' }} />
            <div className='md:hidden block absolute left-[-300px] top-[-400px] bg-[rgb(125, 69, 150)] w-[500px] h-[500px]' style={{ boxShadow: '200px 200px rgb(125, 69, 150)', filter: 'blur(200px)' }} />
            <div className='md:hidden block absolute right-[300px] top-[-400px] bg-[rgb(151, 255, 254)] w-[500px] h-[500px]' style={{ boxShadow: '200px 200px rgb(151, 255, 254)', filter: 'blur(200px)' }} />
          </div>

          <div className='relative'>
            <div className='flex flex-col gap-[60px] md:rounded-[30px] rounded-[20px] bg-transparent md:p-[60px] p-[24px] max-w-[960px] mx-auto z-[3] relative border-[2px] border-white'>
              <div className='flex md:flex-row flex-col md:items-start items-end justify-between md:gap-[80px] gap-[24px]'>
                <div className='w-full flex flex-col gap-[24px]'>
                  <div className='font-medium md:text-[36px] md:leading-[36px] text-[32px] leading-[32px] text-white font-borna w-fit'>For Developers</div>
                  <p className='font-normal md:text-[20px] md:leading-[24px] font-16-18 text-[rgba(255,255,255,0.7)]'>
                    Our API is built for developers, offering seamless integration and customization. Whether you’re building AI-driven applications, running models, or simply need high-quality data, our tools are designed to help you move faster and smarter.
                  </p>
                </div>
                <a
                  href="https://chestnut-radar-416.notion.site/VanaTensor-Docs-13b4cbed730c8066abdff693f13c92be?pvs=73"
                  target="_blank"
                  rel="noopener noreferrer"
                  className='w-fit'
                >
                  <img src={ArrowRightBlackImg} alt='ArrowRightBlackImg' className='md:w-[48px] md:h-[48px] w-[36px] h-[36px]' />
                </a>
              </div>

              <div className='flex md:flex-row flex-col md:items-start items-end justify-between md:gap-[80px] gap-[24px]'>
                <div className='w-full flex flex-col gap-[24px]'>
                  <div className='font-medium md:text-[36px] md:leading-[36px] text-[32px] leading-[32px] text-white font-borna w-fit'>Blog & Resources</div>
                  <p className='font-normal md:text-[20px] md:leading-[24px] font-16-18 text-[rgba(255,255,255,0.7)]'>
                    Stay up to date with our latest innovations, partnerships, and developments in AI by exploring our blog. We also offer detailed guides and documentation to help you get the most out of our platform.
                  </p>
                </div>
                <a
                  href="https://chestnut-radar-416.notion.site/VanaTensor-Docs-13b4cbed730c8066abdff693f13c92be?pvs=73"
                  target="_blank"
                  rel="noopener noreferrer"
                  className='w-fit'
                >
                  <img src={ArrowRightBlackImg} alt='ArrowRightBlackImg' className='md:w-[48px] md:h-[48px] w-[36px] h-[36px]' />
                </a>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </div>
  )
}

export default Home;
