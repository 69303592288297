import { create } from "zustand";
import { persist } from "zustand/middleware";
import { config, networks } from "../../utils/config";

export type Network = keyof typeof networks | string;

type NetworkState = {
  network: Network;
  setNetwork: (network: Network) => void;
  chainId: string;
  rpcUrl: string;
  chainName: string;
  explorerUrl: string;
  currency: string;
  smartContracts: {
    dlp: string;
    dataRegistry: string;
    teePool: string;
  };
};

const defaultNetworkConfig = networks[config.network as keyof typeof networks];

export const useNetworkStore = create<NetworkState>()(
  persist(
    (set) => {
      return {
        network: config.network as Network,
        setNetwork: (network: Network) => {
          const networkConfig = networks[network as keyof typeof networks];
          set({
            network,
            ...networkConfig,
          });
        },
        ...defaultNetworkConfig,
      }
    },
    {
      name: "network-storage",
    }
  )
);
